import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Conversation, SdrConversation } from '../../model/sdr-conversations/sdrs-conversations.model';
import { SnackBarService } from '../../services/snackbar/snackbar.service';
import { SdrConversationsService } from '../../services/sdr-conversations.service';

@Component({
  selector: 'app-sdr-conversations',
  templateUrl: './sdr-conversations.component.html',
  styleUrls: ['./sdr-conversations.component.scss'],
})
export class SdrConversationsComponent implements OnInit, OnDestroy {
  sdrConversations: SdrConversation[] = [];
  selectedConversation: Conversation;
  showExtraInfoSidenav = false;
  hasInteractions = false;
  isLoadingSdrsConversations = false;

  private destroy$: Subject<boolean> = new Subject();
  constructor(
    private sdrConversationsService: SdrConversationsService,
    private snackBarService: SnackBarService,
  ) {
    inject(BreakpointObserver)
      .observe([Breakpoints.WebLandscape])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          this.showExtraInfoSidenav = !result.breakpoints[query];
        }
      });
  }

  ngOnInit(): void {
    this.sdrConversationsService.sdrsConversations$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (sdrConversations: SdrConversation[]) => {
        this.sdrConversations = sdrConversations || [];

        this.setDefaultConversation();
      },
    });

    this.sdrConversationsService.selectedConversation$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation: Conversation) => {
        this.selectedConversation = selectedConversation;
      },
    });

    this.sdrConversationsService.hasInteractions$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (hasInteractions: boolean) => {
        this.hasInteractions = hasInteractions;
      },
    });

    this.loadSdrsConversations();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.sdrConversationsService.setSdrsConversations(null);
  }

  loadSdrsConversations() {
    this.isLoadingSdrsConversations = true;
    this.sdrConversationsService
      .getSdrConversations()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: () => {
          this.snackBarService.showError('An error occurred while retrieving conversations');
        },
        complete: () => {
          this.isLoadingSdrsConversations = false;
        },
      });
  }

  setDefaultConversation() {
    this.sdrConversationsService.setConversation(this.sdrConversations?.[0]?.conversations[0] || null);
  }
}
