import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import { TOKEN_KEY } from '../services/sd-auth.service';
import {LogService} from '../services/log.service';
import 'rxjs-compat/add/operator/map';
import { catchError, switchMap, filter, take, timeout } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { SDAuthService } from '../services/sd-auth.service';
import { environment } from 'src/environments/environment';


const DEFAULT_TIMEOUT = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: SDAuthService) { }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private emailApiToken = `eyJraWQiOiJySjZMdUlPZ3JLWlJuZ0lJWk5Lall6KzN4dGc3TTBzVDJxckU2ZkJtangwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiAiYjk3NjUyMmEtNjZmZi00ZGVmLTgzNGYtZDU3NGVkYmRlYWMyIiwgImNvZ25pdG86Z3JvdXBzIjogWyJtYW5hZ2VyIiwgImZvbGRlcl9Db252ZXJ0ZWQiLCAic2VuZGVyIiwgImxlYWRfaW5xdWlyeSIsICJmb2xkZXJfU2VudCIsICJmb2xkZXJfUkVWSUVXIiwgImZvbGRlcl9PdGhlciIsICJmb2xkZXJfMTlfLV9DYWxsX1VfQmFjayIsICJkZXZlbG9wZXIiLCAiZGlzY3JlcGFuY2llcyJdLCAiZW1haWxfdmVyaWZpZWQiOiB0cnVlLCAiaXNzIjogImh0dHBzOi8vY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb20vdXMtd2VzdC0yXzJhWjlaMDFRVSIsICJjb2duaXRvOnVzZXJuYW1lIjogImI5NzY1MjJhLTY2ZmYtNGRlZi04MzRmLWQ1NzRlZGJkZWFjMiIsICJhdWQiOiAiMzA3OGgxZjJxa2pwcWo4MmVoMTRhN21uaTkiLCAiZXZlbnRfaWQiOiAiNTk3NmZlMjEtZDRjOS00OTYxLWE4NjYtMGM2M2U2OTcyNTdlIiwgInRva2VuX3VzZSI6ICJpZCIsICJhdXRoX3RpbWUiOiAxNjU0NjI3MDQ4LCAiZXhwIjogMTY1NDYzMDY0OSwgImlhdCI6IDE2NTQ2MjcwNDksICJlbWFpbCI6ICJnZW5lcmFsLnRlc3RpbmdAYmFpcmVzZGV2LmNvbSJ9.Cza8md8LUj_d80_480z7EN-b-tdxV1PVipTzYGGS5xCVJWMZPIm0I4qaGVHYvxtKL3o561snZstUDipWucwkyAkLXiNAbP5eVQsX78rCwQSFEBLwzQVqTmufRTm3sUiPT96VWn0kh9RcXN2kh_V0wbH2tVVtntYviLET29Go2fRNTBKkLwgl-WuXYsNADJpwAY-YJZNkPZnujn1f_LVyYoZol15JSp9z71RoPk4UT5KCNBOs5Kb6cgusDfKf1GZdpiDM4AzgMZSFfEySe-7ONUeKjS42Qc35KDlp5LVqe6yohwr3bN04wdv6jpxXn6lztCU9bEZJrO4vz915SOtWKg`;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(request.url.includes('password-public')) { 
      return next.handle(request).pipe(timeout(DEFAULT_TIMEOUT)).pipe(catchError(error => {
        return this.errorHandling(error, request, next)
      }));
    }

    if (request.url.includes(environment.apiBaseURL) || request.url.includes(environment.emailApiBaseURL)) {
      request = this.addToken(request);
      return next.handle(request).pipe(timeout(DEFAULT_TIMEOUT)).pipe(catchError(error => {
        return this.errorHandling(error, request, next)
      }));
    } else {
      return next.handle(request).pipe(timeout(DEFAULT_TIMEOUT)).pipe(catchError(error => {
        return throwError(error);
      }));
    }
  }

  errorHandling(error, request: HttpRequest<any>, next: HttpHandler) {
    console.log("Http error, code: " + error.status + " ,url: " + request.url);
    if (error instanceof HttpErrorResponse && error.status === 401) {
      console.log("Invalid token");
      return this.handle401Error(request, next);
    } else {
      return throwError(error);
    }
  }

  private addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        // 'Content-Type':  'application/json',
        Authorization: request.url.includes(environment.apiBaseURL) ? localStorage.getItem(TOKEN_KEY) : 'Bearer ' + this.emailApiToken
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log("Silent refresh");
    if (!this.isRefreshing) {
      console.log("Silent refresh route 1");
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshSessionToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          console.log("Auth service subscription");
          return next.handle(this.addToken(request)).pipe(timeout(DEFAULT_TIMEOUT));
        }));

    } else {
      console.log("Silent refresh route 2");
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          console.log("Refresh token subject");
          return next.handle(this.addToken(request)).pipe(timeout(DEFAULT_TIMEOUT));
        }));
    }
  }

}
