<mat-accordion
  class="interactions"
  *ngIf="interactionsData && !isLoadingInteractions"
>
  <h3>Interactions</h3>
  <mat-expansion-panel disabled>
    <mat-expansion-panel-header>
      <div class="interactions__header interactions__header--bold">
        <span class="interactions__header-status">Status</span>
        <span class="interactions__header-created-date">Date Created</span>
        <span class="interactions__header-interaction-date">Date Interaction</span>
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-forwarder"
          >Forwarder ID</span
        >
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-campaing"
          >Campaing ID</span
        >
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-template"
          >Template ID</span
        >
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel *ngFor="let interaction of interactionsData?.interactions">
    <mat-expansion-panel-header>
      <div class="interactions__header interactions__header--light">
        <span class="interactions__header-status">{{ interaction.prospectStatus }}</span>
        <span class="interactions__header-created-date">{{
          interaction.utcDatetimeCreated | date: 'YYYY-MM-dd h:mm a'
        }}</span>
        <span class="interactions__header-interaction-date">{{
          interaction.utcDatetimeInteraction | date: 'YYYY-MM-dd h:mm a'
        }}</span>
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-forwarder"
          >{{ interaction?.forwardedContactId }}</span
        >
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-campaing"
          >{{ interaction?.campaignId || '-' }}</span
        >
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-template"
          >{{ interaction?.templateId || '-' }}</span
        >
      </div>
    </mat-expansion-panel-header>
    <app-interaction-item
      [contact]="interactionsData.contact"
      [interaction]="interaction"
    ></app-interaction-item>
  </mat-expansion-panel>
</mat-accordion>

<div
  *ngIf="isLoadingInteractions"
  class="progress-spinner"
>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div
  class="interactions__not-results"
  *ngIf="!interactionsData && !isLoadingInteractions"
>
  <mat-icon
    class="interactions__info-icon"
    color="warn"
    >info</mat-icon
  >
  No interactions found
</div>
