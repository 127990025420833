import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InteractionData } from '../../../model/sdr-conversations/interaction-data';
import { Conversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';

@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss'],
})
export class InteractionsComponent implements OnInit, OnDestroy {
  interactionsData: InteractionData;
  selectedConversation: Conversation;
  isLoadingInteractions = false;
  isMobileOrTablet = false;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private sdrConversationsService: SdrConversationsService,
    private snackBar: MatSnackBar,
  ) {
    inject(BreakpointObserver)
      .observe(['(max-width: 959px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          this.isMobileOrTablet = result.breakpoints[query];
        }
      });
  }

  ngOnInit(): void {
    this.sdrConversationsService.selectedConversation$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (selectedConversation: Conversation) => {
        this.selectedConversation = selectedConversation;
        this.interactionsData = null;

        if (selectedConversation) {
          const { sdrId, conversationId } = selectedConversation;
          this.loadSdrInteractions(sdrId, conversationId);
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadSdrInteractions(sdrId: number, conversationId: string) {
    this.isLoadingInteractions = true;

    this.sdrConversationsService
      .getSdrInteractions(sdrId, conversationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: InteractionData) => {
          this.interactionsData = response;
          this.sdrConversationsService.setHasInteractions(this.interactionsData?.interactions.length ? true : false);
        },
        error: () => {
          this.snackBar.open('An error occurred while retrieving conversations', null, {
            duration: 3000,
          });
          this.sdrConversationsService.setHasInteractions(false);
        },
        complete: () => (this.isLoadingInteractions = false),
      });
  }
}
