import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GenericResponse } from '../model/common/generic-response';
import { ClassifyTrashMessagesDto } from '../model/sdr-conversations/classify-trash-messages.dto';
import { InteractionData } from '../model/sdr-conversations/interaction-data';
import { Conversation, SdrConversation } from '../model/sdr-conversations/sdrs-conversations.model';

@Injectable({
  providedIn: 'root',
})
export class SdrConversationsService {
  private sdrsConversationsSubject = new BehaviorSubject<SdrConversation[]>(null);
  private selectedConversationSubject = new BehaviorSubject<Conversation>(null);
  private hasInteractionsSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  get sdrsConversations$() {
    return this.sdrsConversationsSubject.asObservable();
  }

  setSdrsConversations(sdrsConversations: SdrConversation[]) {
    this.sdrsConversationsSubject.next(sdrsConversations);
  }

  get selectedConversation$() {
    return this.selectedConversationSubject.asObservable();
  }

  setConversation(conversation: Conversation) {
    this.selectedConversationSubject.next(conversation);
  }

  get hasInteractions$() {
    return this.hasInteractionsSubject.asObservable();
  }

  setHasInteractions(hasInteractions: boolean) {
    this.hasInteractionsSubject.next(hasInteractions);
  }

  classifyTrashMessages(classifyTrashMessagesDto: ClassifyTrashMessagesDto) {
    const url = `${environment.newApiBaseUrl}/messages/classify-trash-messages`;

    return this.http.post(url, classifyTrashMessagesDto);
  }

  getSdrConversations(): Observable<SdrConversation[]> {
    const url = `${environment.newApiBaseUrl}/messages/messages-feed`;

    return this.http.get(url).pipe(
      map((response: GenericResponse<SdrConversation[]>) =>
        response.data.map(
          (sdr) =>
            ({
              sdrId: sdr.sdrId,
              sdrName: sdr.sdrName,
              conversations: sdr.conversations.map((conversation) => ({
                ...conversation,
                sdrId: sdr.sdrId,
                sdrName: sdr.sdrName,
              })),
            }) as SdrConversation,
        ),
      ),
      tap((sdrConversations: SdrConversation[]) => {
        this.setSdrsConversations(sdrConversations);
      }),
    );
  }

  getSdrInteractions(sdrId: number, conversationId: string): Observable<InteractionData> {
    const url = `${environment.newApiBaseUrl}/messages/interaction-data/${sdrId}/${conversationId}`;

    return this.http.get(url).pipe(map((response: GenericResponse<InteractionData>) => response.data));
  }

  removeConversation(sdrId: number, conversationId: string): void {
    const currentConversations = this.sdrsConversationsSubject.getValue();

    const updatedConversations = currentConversations.map((sdr) => {
      if (sdr.sdrId === sdrId) {
        return {
          ...sdr,
          conversations: sdr.conversations.filter((conversation) => conversation.conversationId !== conversationId),
        };
      }
      return sdr;
    });

    this.sdrsConversationsSubject.next(updatedConversations);
  }
}
