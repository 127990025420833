<mat-card
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="mat-elevation-z2"
  style="
    background: white;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0;
  "
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    style="cursor: pointer; padding-right: 10px"
  >
    <div
      fxShow="false"
      fxShow.lt-md="true"
    >
      <mat-icon
        (click)="toggleFeed()"
        class="mobileFeedToggle"
        >list</mat-icon
      >
    </div>
    <div>
      <img
        src="assets/images/logo_full.png"
        alt=""
        style="height: 30px; margin-right: 10px"
      />
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-around center"
      class="btnHome"
      (click)="navigateToHome()"
      id="navigate-home"
    >
      <mat-icon class="material-icons-outlined home-icon">home</mat-icon>
      Home
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <a
      fxLayout="row"
      fxLayoutAlign="start center"
      class="contact-row"
      [ngClass.gt-sm]="'contact-row-desktop'"
      [ngClass.lt-md]="'contact-row-mobile'"
      target="_blank"
      href="https://bairesdev.atlassian.net/servicedesk/customer/portal/43/group/240"
    >
      <mat-icon class="material-symbols-outlined face-icon">face</mat-icon>
      <span fxShow.lt-md="false">Contact Support</span>
    </a>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="cursor: pointer; padding-right: 10px"
      [matMenuTriggerFor]="accountMenu"
    >
      <mat-icon
        class="material-symbols-outlined"
        style="color: #515151"
        >account_circle</mat-icon
      >
      <div
        fxShow.lt-md="false"
        style="font-size: 17px; color: #515151; padding-right: 10px; margin-left: 10px"
      >
        {{ userEmail }}
      </div>

      <mat-menu #accountMenu="matMenu">
        <button
          (click)="navigateToManagement()"
          *ngIf="isAdmin"
          mat-menu-item
          id="management-btn"
        >
          Management
        </button>
        <button
          (click)="navigateToDiscrepancies()"
          *ngIf="hasDiscrepanciesPermission"
          mat-menu-item
          id="discrepancies-btn"
        >
          Discrepancies
        </button>
        <button
          (click)="logOut()"
          mat-menu-item
          id="sign-out-btn"
        >
          Sign out
        </button>
      </mat-menu>
    </div>
  </div>
</mat-card>
