import { Component, HostListener, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { Sdr } from '../../../../model/Sdr';
import { FeedService } from '../../../../services/feed.service';
import { Email, FOLDERS } from '../../../../model/Email';
import { SelectedMailService } from '../../../../services/selected-mail.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MailComposerMinComponent } from '../../mail-composer-min/mail-composer-min.component';
import { ComposerMailService } from '../../../../services/composer-mail.service';
import { UserService } from 'src/app/services/user.service';
import { combineLatest, of, Subject, Subscription, timer } from 'rxjs';

import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { BlockedSdrsDialogComponent } from './blocked-sdrs-dialog/blocked-sdrs-dialog.component';

import { MultiselectAutocompleteComponent } from 'src/app/components/multiselect-autocomplete/multiselect-autocomplete.component';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { ComposeEmail } from 'src/app/model/ComposeEmail';
import { ItemData } from 'src/app/model/ItemData';

declare var $: any;

const BLOCKED_SDRS_REFRESH = 60;

@Component({
  selector: 'app-mails-feed',
  templateUrl: './mails-feed.component.html',
  styleUrls: ['./mails-feed.component.scss'],
})
export class MailsFeedComponent implements OnInit, AfterViewInit, OnDestroy {
  feed: Email[] = [];
  clients = [];
  sdrs: Sdr[] = [];
  blockedSdrs: string[] = [];
  clientSdrs = [];
  clientSdrsStr = [];
  folders = [];
  selectedClients = [];
  selectedClientsAutocomplete: ItemData[] = [];
  selectedFolder = '';
  selectedSdrs = [];
  selectedSdrsAutocomplete: ItemData[] = [];
  selectedMailId = '';
  previousFolder = '';
  isSender = false;
  notificationCount = 0;
  blockedSdrsCount = 0;
  blockedSdrsSubscription: Subscription;
  renewBlockedSdrs = true;
  clientValues: any = {
    options: [],
  };
  sdrValues: any = {
    options: [],
  };
  filteredFolderOptions: Observable<any[]>;
  folderFilterControl = new UntypedFormControl();
  currentPage = 0;
  isLoading = false;
  isFeedLoading = false;
  isLastPage = false;
  isComposerOpen = false;
  isOpenBlockedSdr = false;
  sortByOldest = true;
  currentComposedEmail: ComposeEmail;
  susbcriptions = new Subscription();
  destroy$ = new Subject<boolean>();
  @ViewChild('clientsMulti') clientsMulti: MultiselectAutocompleteComponent;
  @ViewChild('sdrsMulti') sdrsMulti: MultiselectAutocompleteComponent;

  constructor(
    private sdApiService: SdApiService,
    private feedService: FeedService,
    private selectedMailService: SelectedMailService,
    private matDialog: MatDialog,
    private composerMailService: ComposerMailService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private loadingStateService: LoadingStateService,
  ) {}

  ngOnInit() {
    this.susbcriptions.add(
      this.loadingStateService.isLoading.subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );

    this.susbcriptions.add(
      this.loadingStateService.isFeedLoading.subscribe((isFeedLoading) => {
        this.isFeedLoading = isFeedLoading;
      }),
    );

    this.sdApiService.loadUserClientsSDRs();
    this.susbcriptions.add(
      this.sdApiService.userFolders.subscribe((folders) => {
        this.selectedFolder = folders[0];
        this.folders = folders.slice().sort();
        this.filteredFolderOptions = of(this.folders.slice());
        this.feedService.updateSelectedFolder(this.selectedFolder);
        this.setInitialClientsAndSdrs(true);
      }),
    );

    this.susbcriptions.add(
      this.sdApiService.userBlockedSdrs.subscribe((blockedSdrs) => {
        this.blockedSdrs = blockedSdrs;
        this.blockedSdrsCount = this.blockedSdrs.length;
      }),
    );

    this.susbcriptions.add(
      this.feedService.feed.subscribe((emailsFeed: Email[]) => {
        this.feed = emailsFeed;
      }),
    );

    this.susbcriptions.add(
      combineLatest([this.feedService.feed, this.feedService.sortByOldest]).subscribe(([feed, sortByOldest]) => {
        this.feed = feed;
        this.sortByOldest = sortByOldest;
        this.orderFeed();
      }),
    );

    this.susbcriptions.add(
      this.selectedMailService.selectedEmail.subscribe((selectedMail) => {
        if (selectedMail) {
          this.selectedMailId = selectedMail.storageId;
        } else {
          this.selectedMailId = '';
        }
      }),
    );

    this.susbcriptions.add(
      this.userService.isUserSender().subscribe((isSender) => {
        this.isSender = isSender;
      }),
    );

    this.susbcriptions.add(
      this.notificationsService.feed.subscribe((notificationsFeed) => {
        this.notificationCount = notificationsFeed.length;
      }),
    );

    this.renewBlockSdrsSubscription();

    this.susbcriptions.add(
      this.feedService.selectedPage.subscribe((currentPage) => {
        this.currentPage = currentPage;
      }),
    );

    this.susbcriptions.add(
      this.feedService.isLastPage.subscribe((isLastPage) => {
        this.isLastPage = isLastPage;
      }),
    );

    this.susbcriptions.add(
      this.composerMailService.isComposerOpen.subscribe((isComposerOpen: boolean) => {
        this.isComposerOpen = isComposerOpen;
      }),
    );
  }

  ngAfterViewInit(): void {
    this.composerMailService.isMailComposerMediumOpen
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpen) => isOpen && this.openMediumDialog());
    this.composerMailService.isMailComposerMinimized
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMinimized) => isMinimized && this.openMinimizedDialog());
  }

  openMediumDialog() {
    const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');
    const dialModalRef = this.matDialog.open(MailComposerMinComponent, {
      panelClass: 'mail-composer-container',
      hasBackdrop: false,
      data: dialogData,
      autoFocus: false,
      disableClose: true,
      maxHeight: '98vh',
    });
    dialModalRef.updatePosition({ bottom: '0', right: '20px' });
  }

  openMinimizedDialog() {
    const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');

    this.matDialog.open(MailComposerMinComponent, {
      panelClass: 'mail-composer-container',
      hasBackdrop: false,
      data: dialogData,
      autoFocus: false,
      disableClose: true,
      width: '400px',
      height: '36px',
      position: { bottom: '0', right: '20px' },
    });
  }

  updateSdrs() {
    this.feedService.updateSdrs(this.selectedSdrs);
  }

  updateClients(client: ItemData) {
    this.feedService.updateClients(this.selectedClients);
    this.selectedSdrs = this.sdrs.filter((sdr) => this.selectedClients.includes(sdr.client));
    this.clientSdrs = this.sdrs.filter((sdr) => this.selectedClients.includes(sdr.client));
    this.clientSdrsStr = this.clientSdrs.map((cSdr) => cSdr.email);

    this.selectedSdrsAutocomplete = this.selectedSdrs.map((sdr) => ({
      item: sdr.email,
      selected:
        client.item === sdr.client && client.selected === true
          ? true
          : this.selectedSdrsAutocomplete.find((sSdrs) => sSdrs.item === sdr.email).selected,
    }));

    this.feedService.updateSdrs(this.selectedSdrs);
  }

  updateFolder() {
    this.feedService.selectedFolder.take(1).subscribe((previousFolder) => {
      this.previousFolder = previousFolder;
      this.feedService.updateSelectedFolder(this.selectedFolder);
      this.selectedMailService.selectMail(null);
      this.selectedMailService.clearBulkSelectMail();

      if (this.selectedFolder) {
        this.setInitialClientsAndSdrs();
      } else {
        this.feedService.updateSdrs([]);
      }
    });
  }

  reload() {
    this.selectedMailService.refresh();
    this.renewBlockSdrsSubscription();
  }

  selectAllClients() {
    this.selectedSdrs = this.sdrs;
    this.selectedClients = this.clients;
    this.clientSdrs = this.sdrs;
    this.clientSdrsStr = this.clientSdrs.map((cSdr) => cSdr.email);
    this.clientsMulti.selectAll();

    this.selectedSdrsAutocomplete = this.selectedSdrs.map((sdr) => ({
      item: sdr.email,
      selected: true,
    }));

    this.feedService.updateClients(this.selectedClients);
    this.feedService.updateSdrs(this.selectedSdrs);
  }

  clearAllClients() {
    this.selectedSdrs = [];
    this.clientSdrs = [];
    this.clientSdrsStr = this.clientSdrs.map((cSdr) => cSdr.email);
    this.selectedClients = [];
    this.clientsMulti.clearAll();
    this.selectedClientsAutocomplete.forEach((client) => {
      client.selected = false;
    });
    this.feedService.updateSdrs([]);
    this.feedService.updateClients([]);
    this.selectedMailService.clearBulkSelectMail();
  }

  selectAllSDRs() {
    this.selectedSdrs = this.sdrs.filter((sdr) => this.selectedClients.includes(sdr.client));
    this.sdrsMulti.selectAll();
    this.feedService.updateSdrs(this.selectedSdrs);
  }

  clearAllSDRs() {
    this.selectedSdrs = [];
    this.sdrsMulti.clearAll();
    this.selectedMailService.clearBulkSelectMail();
    this.feedService.updateSdrs([]);
  }

  toggleOrder(order: string) {
    this.feedService.sortFeed(order);
  }

  orderFeed() {
    const ordered = this.feed;
    ordered.sort((a, b) => {
      if (a.dateMillis > b.dateMillis) {
        return this.sortByOldest ? 1 : -1;
      } else if (a.dateMillis === b.dateMillis) {
        return 0;
      } else {
        return this.sortByOldest ? -1 : 1;
      }
    });
    this.feed = ordered;
  }

  orderSdr(sdrs: Sdr[]) {
    const ordered = sdrs;
    ordered.sort((a, b) => {
      if (a.email > b.email) {
        return 1;
      } else if (a.email === b.email) {
        return 0;
      } else {
        return -1;
      }
    });
    this.sdrs = ordered;
  }

  compose() {
    this.composerMailService.composeNew();
    this.composerMailService.setIsMailComposerMinimized(false);
    this.composerMailService.setIsMailComposerMediumOpen(false);
    const matDialogRef = this.matDialog.open(MailComposerMinComponent, {
      panelClass: 'mail-composer-container',
      hasBackdrop: false,
      autoFocus: false,
      disableClose: true,
    });
    matDialogRef.updatePosition({ bottom: '0', right: '20px' });
  }

  clearNotifications() {
    this.notificationsService.removeAllNotifications();
  }

  selectClientChange = (event: any) => {
    const key: string = event.key;
    this.clientValues[key] = [...event.data];
    this.selectedClients = this.clientValues.options;
    this.updateClients(event.changedItem);
  };

  selectSdrChange = (event: any) => {
    const key: string = event.key;
    this.sdrValues[key] = [...event.data];
    this.selectedSdrs = this.clientSdrs.filter((cSdr) => this.sdrValues.options.some((opt) => opt === cSdr.email));
    if (!event.changedItem.selected) {
      this.selectedMailService.filterEmailOfUnselectedSdr(event.changedItem.item);
    }
    this.updateSdrs();

    const filteredSdrs = this.sdrs.filter((sdr) => this.selectedClients.includes(sdr.client));

    this.selectedSdrsAutocomplete = filteredSdrs.map((sdr) => ({
      item: sdr.email,
      selected: this.selectedSdrs.some((sSdr) => sSdr.email === sdr.email),
    }));
  };

  displayFn(inputStr: string): string {
    return inputStr ? inputStr : '';
  }

  openBlockedSdrs() {
    this.isOpenBlockedSdr = true;
    this.renewBlockSdrsSubscription();
  }

  renewBlockSdrsSubscription() {
    if (this.blockedSdrsSubscription) {
      this.blockedSdrsSubscription.unsubscribe();
      this.blockedSdrsSubscription = undefined;
    }

    const source = timer(0, BLOCKED_SDRS_REFRESH * 1000);

    this.blockedSdrsSubscription = source
      .pipe(mergeMap(() => this.sdApiService.getBlockedSdrs()))
      .subscribe((blockedSdrs) => {
        this.sdApiService.blockedSdrs.next(blockedSdrs);
        if (this.isOpenBlockedSdr) {
          this.isOpenBlockedSdr = false;
          const dialogConfig = new MatDialogConfig();
          dialogConfig.maxHeight = '600px';
          dialogConfig.data = this.blockedSdrs;
          this.matDialog.open(BlockedSdrsDialogComponent, dialogConfig);
        }
      });
  }

  isMailSelected(email) {
    return this.selectedMailId === email.storageId || this.selectedMailService.isMailInBulkSelection(email);
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange($event: Event): void {
    if (document.hidden) {
      this.renewBlockedSdrs = true;
    } else {
      if (this.renewBlockedSdrs) {
        this.renewBlockSdrsSubscription();
        this.renewBlockedSdrs = false;
      }
    }
  }

  previous() {
    if (this.currentPage !== 0 && !this.isLoading) {
      this.feedService.pageChanged = true;
      this.feedService.setPreviousPage();
    }
  }

  next() {
    if (!this.isLastPage && !this.isLoading) {
      this.feedService.pageChanged = true;
      this.feedService.setNextPage();
    }
  }

  ngOnDestroy() {
    this.blockedSdrsSubscription.unsubscribe();
    this.susbcriptions.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private setInitialClientsAndSdrs(loadAllSdrs = false) {
    if (this.selectedFolder === FOLDERS.REVIEW) {
      this.sdApiService.userSdrsReview.take(1).subscribe((sdrs) => {
        this.setSdrsData(sdrs, loadAllSdrs);
      });

      this.sdApiService.userClientsReview.take(1).subscribe((clients: any[]) => {
        this.setClientsData(clients, loadAllSdrs);
      });
    } else if (this.selectedFolder === FOLDERS.SENT) {
      this.sdApiService.userSdrsSent.take(1).subscribe((sdrs) => {
        this.setSdrsData(sdrs, loadAllSdrs);
      });

      this.sdApiService.userClientsSent.take(1).subscribe((clients: any[]) => {
        this.setClientsData(clients, loadAllSdrs);
      });
    } else {
      this.sdApiService.userSDRs
        .pipe(filter((sdrs) => !!sdrs?.length))
        .take(1)
        .subscribe((sdrs) => {
          this.setSdrsData(sdrs, loadAllSdrs);
        });

      this.sdApiService.userClients
        .pipe(filter((clients) => !!clients?.length))
        .take(1)
        .subscribe((clients: any[]) => {
          this.setClientsData(clients, loadAllSdrs);
        });
    }
  }

  private setSdrsData(sdrs, loadAllSdrs) {
    this.orderSdr(sdrs);
    this.clientSdrs = this.sdrs;
    this.clientSdrsStr = this.clientSdrs.map((cSdr) => cSdr.email);
    const filteredSdrs = this.sdrs.filter((sdr) => this.selectedClients.includes(sdr.client));

    this.selectedSdrs =
      loadAllSdrs ||
      this.selectedFolder === FOLDERS.SENT ||
      this.selectedFolder === FOLDERS.REVIEW ||
      this.previousFolder === FOLDERS.SENT ||
      this.previousFolder === FOLDERS.REVIEW
        ? this.sdrs
        : filteredSdrs.filter((sdr) => this.selectedSdrs.some((sSdrs) => sSdrs.email === sdr.email));

    if (
      loadAllSdrs ||
      this.selectedFolder === FOLDERS.SENT ||
      this.selectedFolder === FOLDERS.REVIEW ||
      this.previousFolder === FOLDERS.SENT ||
      this.previousFolder === FOLDERS.REVIEW
    ) {
      this.selectedSdrsAutocomplete = this.sdrs.map((sdr) => ({
        item: sdr.email,
        selected: this.selectedSdrs.some((sSdr) => sSdr.email === sdr.email),
      }));
    } else {
      this.selectedSdrsAutocomplete = this.selectedClients.length > 0 ? [...this.selectedSdrsAutocomplete] : [];
    }

    this.feedService.updateSdrs(this.selectedSdrs);
  }

  private setClientsData(clients, loadAllSdrs) {
    this.clients = clients.map((c) => c.name);

    this.selectedClients =
      loadAllSdrs ||
      this.selectedFolder === FOLDERS.SENT ||
      this.selectedFolder === FOLDERS.REVIEW ||
      this.previousFolder === FOLDERS.SENT ||
      this.previousFolder === FOLDERS.REVIEW
        ? this.clients
        : this.clients.filter((client) => this.selectedClients.some((sClient) => sClient === client));

    this.selectedClientsAutocomplete = this.clients.map((client) => ({
      item: client,
      selected: this.selectedClients.some((x) => x === client),
    }));

    this.feedService.updateClients(this.selectedClients);
  }
}
