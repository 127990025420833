import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GenericResponse } from '../model/common/generic-response';

@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  constructor(private httpClient: HttpClient) {}

  checkInteractionExist(attr: { messageId: string; sdrId: number; statusId: number }): Observable<boolean> {
    const url = `${environment.newApiBaseUrl}/interactions/check-interaction-exists`;

    return this.httpClient.post(url, attr).pipe(map((response: GenericResponse<boolean>) => response.data));
  }
}
